.ChSio {
  background-color: transparent;
  height: 45px;
  border: 1px solid rgb(92, 114, 125);
  border-radius: 4px;
  padding-left: 8px;
  padding-right: 8px;
  color: rgb(25, 48, 61);
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
  width: calc(100% - 18px);
}

.fbrajt {
  all: unset;
  width: 95%;
  height: 45px;
  cursor: pointer;
  font-size: 18px;
  text-transform: none;
  margin-top: 20px;
  font-family: Arial, Helvetica, sans-serif;
  box-shadow: none;
  background-color: rgb(25, 48, 61) !important;
  color: white;
  text-align: center;
  border-radius: 4px;
}

.header-data .nav-links a {
  color: #000 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-lightGreen {
  --tw-text-opacity: 1;
  background-color: rgb(198 255 224 / var(--tw-text-opacity));
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.custom-tab-design li.nav-item > button.active {
  background-color: #009ef7;
  color: white;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0px 0px;
}

.custom-tab-design li.nav-item button {
  background-color: #1e1e2d;
  border: 1px solid #ccc;
  border-radius: 10px 10px 0px 0px;
  color: white;
}

.booking-view-button {
  background-color: #009ef7 !important;
  color: #fff !important;
  font-size: 12px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.customtimeline .timeline {
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.customtimeline .li {
  transition: all 200ms ease-in;
}

.customtimeline .timeline .date {
  font-size: 10px;
}

.customtimeline .timestamp {
  margin-bottom: 20px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 100;
}
.customtimeline .status {
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  border-top: 2px solid #d6dce0;
  position: relative;
  transition: all 200ms ease-in;
}
.customtimeline h4 {
  font-size: 10px;
}
.customtimeline h4:before {
  content: "";
  width: 25px;
  height: 25px;
  background-color: white;
  border-radius: 25px;
  border: 1px solid #ddd;
  position: absolute;
  top: -15px;
  left: 42%;
  transition: all 200ms ease-in;
}
.customtimeline .li.complete .status {
  border-top: 2px solid #66dc71;
}
.customtimeline .li.complete .status:before {
  background-color: #66dc71;
  border: none;
  transition: all 200ms ease-in;
}
.customtimeline .li h4 {
  margin-top: 20px;
}

.customtimeline .li.complete h4 {
  color: #66dc71;
  margin-top: 20px;
}

.customtimeline .li.complete h4:before {
  background-color: #66dc71;
}

.viewbookingdate {
  font-size: 20px;
  font-family: "Roboto Condensed";
  font-weight: bold; 
  text-align: center;
  border: 2px solid #1e1541;
  background-color: transparent; 
  &:focus {
    outline: none;
  }
}

.inprogressbookingdate {
  font-size: 15px;
  font-family: "Roboto Condensed";
  font-weight: bold; 
  text-align: center;
  border: 2px solid #1e1541;
  background-color: white; 
  width: 120px;
  &:focus {
    outline: none;
  }
}

.horizontal-filters-sortContainer{
  display: inline-block;
  float: right;
  margin-right: 30px;
  margin-bottom: 0;
  margin-top: -7px;
  position: relative;
  top: 5px;
}

.login-avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; 
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; 
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.login-auth-card .auth-effect-main .auth-user-list li {
  position: absolute;
}

.login-auth-card .auth-effect-main .auth-user-list li:nth-child(1) {
  bottom: 82px;
  left: 48px;
}

.login-auth-card .auth-effect-main .auth-user-list li:nth-child(2) {
  top: 95px;
  left: 66px;
}
.login-auth-card .auth-effect-main .auth-user-list li:nth-child(3) {
  top: 20px;
  right: 98px;
}
.login-auth-card .auth-effect-main .auth-user-list li:nth-child(4) {
  bottom: -15px;
  right: 144px;
}
.login-auth-card .auth-effect-main .auth-user-list li:nth-child(5) {
  bottom: 185px;
  right: 35px;
}
.login-auth-card .mx-auto {
  margin-right: auto!important;
  margin-left: auto!important;
}


.mrnno-spinners {
  -moz-appearance: textfield;
}

.mrnno-spinners::-webkit-outer-spin-button,
.mrnno-spinners::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.app-sidebar .menu .menu-item .menu-link.active{
  background-color: #008ffb !important;
}

#kt_inbox_form_editor .ck-editor__editable_inline {
  height: 400px;
}